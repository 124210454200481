.subtaskSection {
    margin-bottom: 20px;
}

.subtaskSection h4 {
    font-size: 15px;
}

.subtasktext {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.subtasktext span {
    margin-right: 10px;
}

.icon_subtask {
    color: #FFCC00;
    font-size: 18px;
}

.subtaskSection ul li {
    margin-bottom: 5px;
    list-style: none;
    padding-left: 3px;
    font-size: 13px;
}


.subtaskInputContainer {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.checkboxInput {
    display: none;
}

.customCheckbox {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: transparent; 
    border-radius: 2px;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid #242424;
}


.checkboxInput:checked + .customCheckbox {
    border: solid 2px #FFCC00;
}

.checkboxInput:checked + .customCheckbox::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 3px;
    width: 3px;
    height: 6px;
    border: solid #FFCC00;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.subtaskInput {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 350px;
    height: 36px;
    background-color: #242424;
    color: #fff;
    outline-color: #FFCC00;
    margin-right: 10px;

}