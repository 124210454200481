.main {
    margin-top: 20px;
    margin-right: 6px;
    position: fixed;
    left: 17.5vw;
    padding-left: 30px;
    height: 90vh; 
    overflow-y: auto; 
}


.main::-webkit-scrollbar {
    width: 8px; 
}

.main::-webkit-scrollbar-track {
    background: #2c2c2c; 
    border-radius: 4px; 
}

.main::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px; 
    transition: background 0.3s ease; 
}

.main::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.main {
    scrollbar-color: #888 #2c2c2c; 
    scrollbar-width: thin; 
}