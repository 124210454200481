.textareaContainer {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    margin-top: 30px;
}

.textarea {
    width: 46vw;
    height: 300px;
    border-radius: 5px;
    border: none;
    padding: 13px;
    background-color: #242424;
    color: #ffffff;
    resize: none;
    outline: none;
}

