.sidebar {
    position: fixed; 
    top: 0; 
    left: 0; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 17vw;
    height: 100vh; 
    padding: 20px;
    background-color: #252525;
    transition: all 0.35s;
}

.sidebar_header {
    display: inline-flex;
    align-items: center;
    width: 100%; 
    height: 48px;
    gap: 2px;
    padding: 8px;
    margin-bottom: 20px;
}

.sidebar_header span {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
}
.item_not_image {
    width: 100%;
}
.logo {
    width: 40px;
    background-color: #FFF7DE;
    border-radius: 5px;
    padding: 6px;
}

.sidebar_list {
    padding: 0;
    list-style: none;
    width: 15vw;
    color: #fff;
    margin-bottom: 13vh; 
}

.sidebar a {
    text-decoration: none; 
    color: inherit; 
}

.sidebar span {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    height: 44px;
    padding: 12px;
}

.item {
    color: #fff;
    width: 15vw;
    margin-bottom: 4px;
}

.btn_item {
    width: 14.5vw;
    cursor: pointer;
}

.item span > span {
    color: #fff;
    font-size: 15px;
}

.btn_item:hover {
    background-color: #414141;
    border-radius: 4px;
    transition: .4s;
    color: #ddb120;
}

.icone_video {
    font-size: 20px;
}

/* Mais itens mais abaixo do primeiro menu */
.sidebar_list_down {
    padding: 0;
    list-style: none;
    width: 100%;
    color: #fff;
    margin-bottom: 10%; 
}

.btn_item_list_down {
    width: 100%; 
    cursor: pointer;
}

.item_list_down span > span {
    color: #fff;
    font-size: 15px;
}

.sidebar_list_down_logout {
    padding: 12px 0; 
    list-style: none;
    width: 15%;
    color: #fff;
    position: fixed;
    bottom: 10px;
}

.sidebar_list_down_logout button span {
    font-size: 15px; 
   
}

.sidebar_list_down_logout button svg {
    font-size: 15px;
    margin-right: 15px;
}



/* Ajustando sidebar com apenas os ícones */
@media (max-width: 920px) { 
    .sidebar {
        width: 80px;
    }
    
    .sidebar_header > span {
        display: none; 
    }

    .btn_item {
        width: 40px;
        cursor: pointer;
        position: relative;
    }
    
    .btn_item > span {
        width: 50px;
        height: 44px; 
        display: flex; 
        align-items: center;
        justify-content: center; 
        transition: background-color 0.4s, color 0.4s;
    }
    
    .btn_item:hover > span {
        background-color: #414141;
        color: #ddb120;
    }

    .sidebar .item > span > span {
        display: none; 
    }

    .sidebar_header {
        justify-content: center;
    }

    /* Menu mobile vai ser desenvolvido depois, não é prioridade agora */
}
