.content_profile {
    width: 75vw;
    margin-left: 18px;
    height: auto;
    background-color: #252525;
    border-radius: 10px;
    display: flex;
    flex-direction: column;  
    padding: 40px;
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
}

.content_profile .loading {
    align-self: center;  
    justify-content: center;
}

.top_info {
    width: 90vw;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.imguser_content {
    margin-right: 30px; 
}

.imguser_content img {
    width: 148px; 
    border-radius: 50%;
    height: 148px;
}

.name_content h4 {
    padding-top: 30px;
    font-size: 30px;
    font-weight: lighter;
    color: #fff;
    margin-bottom: 15px;
}

.name_content p {
    color: #A9A5A5;
    font-size: small;
    font-weight: lighter;
}

.line {
    background-color: #4E4D4D;
    width: 100%;
    height: 1px;
}

.personal_info {
    width: 100%;
    margin-top: 30px;
    border: solid 1px #484848;
    border-radius: 10px;
    padding: 20px;
}
.personal_info_top {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
}

.personal_info_top span {
    cursor: pointer; 
    display: flex;
    align-items: center; 
}

.edit_button {
    color: #494949;
    border: solid 1px #494949;
    display: flex;
    border-radius: 5px;
    padding: 5px;
    align-items: center; 
}

.edit_button button {
    color: #848484;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
}

.edit_button svg {
    color: #656565;
    font-size: 17px; 
}

.personal_info_top a {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
}

.personal_info_bottom {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.div_margin {
    margin-right: 13%;
}


.personal_info_bottom a {
    font-size: 15px;
    color: #A9A5A5;
}

.personal_info_bottom p {
    font-size: 15px;
    margin-top: 12px;
    color: #fff;
}