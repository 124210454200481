.form_login {
    display: flex;
    justify-content: space-between;
    height: 100vh; 
    background-color: #242424; 
  }

.logo_container {
    position: relative;
    overflow: hidden;
    width: 60vw;
    height: 100%;
    display: flex;
    justify-content: center;
}

.logo_container img {
    margin-right: 75px;
    width: 350px;
}

.ball {
    position: absolute;
    width: 129px; 
    height: 127px; 
    border-radius: 50%; 
    background-color: #34C759; 
    filter: blur(80px); 
}

.ball1 {
    animation: moveBall1 8s linear infinite; 
}

.ball2 {
    animation: moveBall2 8s linear infinite; 
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
 .content_loader_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10vw;
    height: 15vh;
    background-color: #242424;
    border-radius: 5px;
    color: #fff;
 }

 .content_loader_login p {
    font-weight: lighter;
    font-size: smaller;
 }

.loader {
    margin-bottom: 20px;
    border: 6px solid transparent; 
    border-top: 6px solid #34C759; 
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; 
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes moveBall1 {
    0% {
        left: 0; /* Começa no canto superior esquerdo */
        top: 0; /* Começa no canto superior esquerdo */
    }
    25% {
        left: 100%; /* Move para o canto superior direito */
        top: 0;
        transform: translate(-100%, 0); /* Ajusta para fora do contêiner */
    }
    50% {
        left: 100%; /* Move para o canto inferior direito */
        top: 100%;
        transform: translate(-100%, -100%);
    }
    75% {
        left: 0; /* Move para o canto inferior esquerdo */
        top: 100%;
        transform: translate(0, -100%);
    }
    100% {
        left: 0; /* Retorna ao canto superior esquerdo */
        top: 0;
    }
}

@keyframes moveBall2 {
    0% {
        left: 100%; /* Começa no canto superior direito */
        top: 100%; /* Começa no canto inferior direito */
    }
    25% {
        left: 0; /* Move para o canto inferior esquerdo */
        top: 100%;
        transform: translate(0, 0);
    }
    50% {
        left: 0; /* Move para o canto superior esquerdo */
        top: 0;
        transform: translate(0, 0);
    }
    75% {
        left: 100%; /* Move para o canto superior direito */
        top: 0;
        transform: translate(-100%, 0); /* Mantém a bolinha visível */
    }
    100% {
        left: 100%;
        top: 105%; 
        transform: translate(-100%, 0); 
    }
}

  
.form_container {
    border: none;
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #353535;
    padding: 2rem;
    height: 100%;
    text-align: center; 
}

.form {
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 60%;
}
 
h3 {
    font-size: 50px;
    font-style: initial;
    font-weight: lighter;
    margin-bottom: 1.5rem;
    color: #fff;
}
  

.form p {
    color: #d9534f; 
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.form input {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #242424;
    border-radius: 2px;
    font-size: smaller;
    background-color: #242424;
    outline: none;
    color: #fff;
    transition: border-color 0.3s;
}
.form input::placeholder {
    color: #fff;
    font-weight: lighter;
    font-size: smaller;
}
  
.form input:focus {
    border-color: #34C759; 
}
  
 
.btn_login {
    margin-top: 40px;
    width: 100%;
    padding: 0.8rem;
    background-color: #34C759;
    color: hsl(0, 0%, 100%);
    border: none;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 50px;
}
  
.btn_login:hover {
    background-color: #242424; 
    border: 1px solid #34C759;
}

.form_container span a {
    color: #FFF;
    font-weight: 400;
}

.login_google_container {
    margin: 50px auto; 
    border: solid 2px #242424;
    padding: 0.4rem;
    background-color: transparent;
    border-radius: 4px;
    width: 60%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* .img_google_icon {
    width: 20px;
    height: 20px;
} */

.btn_login_google {
    width: 70%;
    color: #fff;  
    font-weight: bold;
}
  