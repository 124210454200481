.main {
   
    margin-right: 6px;
    position: fixed;
    left: 17.5vw;
    padding-top: 20px;
    padding-left: 30px;
    height: 90vh; 
    overflow-y: auto; 
}


.main::-webkit-scrollbar {
    width: 8px; 
}

.main::-webkit-scrollbar-track {
    background: #2c2c2c; 
    border-radius: 4px; 
}

.main::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px; 
    transition: background 0.3s ease; 
}

.main::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.main {
    scrollbar-color: #888 #2c2c2c; 
    scrollbar-width: thin; 
}


.text_welcome {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    width: 80vw;
    padding: 8px;
}

h2 {
    font-size: 25px;
    color: #fffffd;
    font-weight: 500;
    margin-right: 30px;
}



.filter_container {
    display: flex;
    flex-wrap: nowrap; 
    flex-direction: row;
    overflow-x: auto; 
    gap: 25px;
    margin-right: 10px; 
    max-width: 100%;
    padding: 0;
}

.content_filter_task {
    display: flex;
    flex-direction: row;
}

.name_status {
    margin-right: 25px;
}

@media (max-width: 920px) {
    .main {
        transition: margin 0.4s;
        margin-left: -20px;
    } 

    .filter_container {
        max-width: 90%;
        overflow-x: auto;
    }
}
