.filter_container {
    width: 83%;
    display: flex;
    align-items: center;
    padding: 8px;
}

.filter_btn {
    padding: 10px 30px 10px 0px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
}

a {
    text-decoration: none;
}

span {
    text-decoration: none;
    color: #fff;
}

.btn_filter_text {
    margin-left: auto; 
}
