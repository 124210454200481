.selectContainer {
    width: 138px;
    margin: 20px;
    height: 29px;
    position: relative;
  }
  
.styledSelect {
    outline: none;
    width: 138px;
    padding: 10px;
    border-radius: 2px;
    background-color: transparent;
    font-size: 13px;
    color: #333;
    outline: none;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    transition: all 0.3s ease;
}
  
.styledSelect:focus {
    border-color: transparent;
}
  

.selectBaixa {
    background-color: #1252229E;
    color: #fff;
    font-size: 10px;
}
  
.selectMedia {
    background-color: #79712a; 
    font-size: 10px;
    color: #fff;
}
  
.selectAlta {
    background-color: #B4373787; 
    font-size: 10px;
    color: #fff;
}
  

.styledSelect::after {
    content: '▼';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: #333;
    pointer-events: none; 
}
  