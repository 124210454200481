.input_notes {
    background-color: #242424;
    border: none; 
    border-radius: 5px;
    padding: 15px;
    font-size: 14px; 
    outline: none;
    flex: 1; 
    width: 46vw;
    color: #fff;
    margin-top: 8px;
}

.input_notes::placeholder {
    color: #606060;
}