* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html, body, #root {
  background-color: #353535;
  height: 100%;
}