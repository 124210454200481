.sumary_container {
    margin-top: 20px;
    padding-right: 18px;
}

.ul_sumary {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(23vw, 1fr)); 
}

.sumary_card {
    background-color: #252525;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}


.card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_header svg {
    font-size: 22px;
}

.sumary_card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.color_container {
    width: 100%;
    height: 89px;
    background-color: #FF3B30;
    border-radius: 5px;
}

.sumary_title_container {
    margin-top: 20px;
}

.sumary_title {
    font-size: 23px;
    font-weight: lighter;
    color: #fff;
}

.sumary_description {
    font-size: 1em;
    color: #6F6F6F;
    margin: 10px 0;
    max-height: 50px;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.sumary_description.expanded {
    max-height: 300px; 
    overflow-y: auto;
}


.sumary_description.expanded::-webkit-scrollbar {
    width: 8px; 
}

.sumary_description.expanded::-webkit-scrollbar-track {
    background: #2c2c2c; 
    border-radius: 4px; 
}

.sumary_description.expanded::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px; 
    transition: background 0.3s ease; 
}

.sumary_description.expanded::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.sumary_description.expanded {
    scrollbar-color: #888 #2c2c2c; 
    scrollbar-width: thin; 
}


.toggle_button {
    background-color: #353535;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.toggle_button:hover {
    background-color: #444444;
}

.sumary_card:hover .toggle_button {
    background-color: #444444;
}
