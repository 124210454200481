.card_add_task {
    width: 18vw;
    background-color: #252525;
    height: 47px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    color: #fffffd;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.card_add_task h4 {
    font-size: 15px;
    font-weight: 500;
}

button {
    border: none;
    background-color: transparent;
    color: #fffffd;
    cursor: pointer;
}

.status_text {
    display: flex;
    align-items: center;
    gap: 12px; 
}

.status_text h4 {
    padding-left: 7px;
}

.status_dot {
    width: 6px; 
    height: 6px;
    border-radius: 50%;
}


